import { css } from '@emotion/core';
import styled from '@emotion/styled/macro';
import { graphql, Link } from 'gatsby';
import Img from 'gatsby-image/withIEPolyfill';
import React from 'react';

import { ProjectHeader } from '../../components/Projecten/ProjectHeader';
import { ProjectIntro } from '../../components/Projecten/ProjectIntro';
import { ProjectMobile } from '../../components/Projecten/ProjectMobile';
import { ProjectQuote } from '../../components/Projecten/ProjectQuote';
import { ProjectTextContainer } from '../../components/Projecten/ProjectTextContainer';
import { ProjectVideo } from '../../components/Projecten/ProjectVideo';
import { ProjectsCarousel } from '../../components/ProjectsCarousel/ProjectsCarousel';
import { Root } from '../../components/Root';
import { IgnoreMaxWidth } from '../../components/UI/IgnoreMaxWidth';
import { Text } from '../../components/UI/Text';
import { COLORS } from '../../constants';
import { Breakpoint } from '../../types';
import { forBreakpoint } from '../../utils/forBreakpoint';
import { getImageByAlt } from '../../utils/getImageByAlt';

export const query = graphql`
  {
    allSanityProject(filter: {Client: {eq: "Tomaten De Maeyer"}}) {
      edges {
        node {
          Gallery {
            Alt
            asset {
              fluid(maxWidth: 2000) {
                aspectRatio
                sizes
                base64
                src
                srcSet
              }
            }
          }
        }
      }
    }
  }
`;

const tomatenDeMaeyer = ({ data }) => {
  const images = data.allSanityProject.edges[0].node.Gallery;

  const tomatenDark = getImageByAlt(images, 'tomaten-dark');
  const tomatenExample = getImageByAlt(images, 'tomaten-example');
  const tomatenPhone = getImageByAlt(images, 'tomaten-phone');
  const tomatenHeader = getImageByAlt(images, 'tomaten-header');
  const tomatenLight = getImageByAlt(images, 'tomaten-light');

  return (
    <>
      <ProjectHeader image={tomatenHeader.asset.fluid.src} />
      <Root>
        <ProjectIntro tags="#web #graphicdesign #2019" title="Ontdek de pure smaak van grondgeteelde tomaten bij De Maeyer" />

        <TomatenExample>
          <TomatenExampleImage fluid={tomatenExample.asset.fluid} alt={tomatenExample.Alt} />
        </TomatenExample>

        <ProjectTextContainer>
          <Text mode="projectText">
            Voor Tomatenkwekerij De Maeyer ontwikkelden we een volledig nieuwe look. We staken hun design in een <i>brand new</i> jasje:
            we creëerden een gloednieuw logo en maakten een frisse nieuwe website met aantrekkelijk foto- en videomateriaal.
          </Text>
          <br /><br />
          <Text mode="normal">
            Benieuwd? Bekijk hun nieuwe website op &nbsp;
            <Link to={'https://vollegrondtomaat.be'} target="_blank">
              <u>Vollegrondtomaat.be</u>
            </Link>
          </Text>
        </ProjectTextContainer>

        <BrandingContainer>
          <BrandingImg fluid={tomatenLight.asset.fluid} alt={tomatenLight.Alt} />
          <BrandingImg fluid={tomatenDark.asset.fluid} alt={tomatenDark.Alt} />
        </BrandingContainer>

        <ProjectMobile
          title="Koop lokaal"
          text="Door rechtstreeks te kopen bij de producent draag je bij tot het korte ketenverhaal en je krijgt een supervers product!"
          mobileImage={tomatenPhone}
          backgroundColor={COLORS.CwxGreen}
        />

        <ProjectTextContainer>
          <Text mode="projectText">
            Bent u op zoek naar een tomaat met een meerwaarde?
            Die kan u bij ons vinden! Wij, dat zijn Hilde en Werner, telen al sinds 1990 tomaten op ons bedrijf te Putte.
            Sinds 2015 zijn we overgeschakeld naar een volledige grondteelt van tomaten.
          </Text>
        </ProjectTextContainer>

        <ProjectVideo source="https://vimeo.com/441101111/ef4d393541" color="#FFF6CE" />

        <ProjectQuote
          title='"Eigenlijk is het eenvoudig: terug naar de basis, de natuurlijkste manier van het groeiproces."'
          tag="Hilde & Werner"
          text="Onze planten staan met hun wortels in de grond,  mogen traag groeien op hun tempo. Zongerijpt en rood geoogst geeft dit een vaste, bijzondere smaakvolle tomaat! " />

        <ProjectsCarousel carouselTitle="Er is meer." forProject={true} />
      </Root>
    </>
  );
};

export default tomatenDeMaeyer;

const TomatenExample = styled.div`
  position: relative;
  margin-top: 4rem;
  display: flex;
  justify-content: center;
   
  ${forBreakpoint(Breakpoint.TabletLandscape, css`
    margin-top: 6rem;
  `)}
`;

const TomatenExampleImage = styled(Img)`
  width: 100%;

  ${forBreakpoint(Breakpoint.TabletLandscape, css`
    width: 80%;
  `)}
`;

const BrandingContainer = styled.div`
  ${IgnoreMaxWidth};
  margin-bottom: -5px;

  ${forBreakpoint(Breakpoint.TabletLandscape, css`
    display: flex;
  `)}
`;

const BrandingImg = styled(Img)`
  width: 100%;

  ${forBreakpoint(Breakpoint.TabletLandscape, css`
    width: 50%;
  `)}
`;
